export default [
  {
    title: 'Activity Feed',
    route: 'pages-feed',
    icon: 'LayersIcon',
  },
  {
    title: 'Calendar',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Chat',
    route: 'apps-chat',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'Share Profile',
    icon: 'Share2Icon',
    route: 'apps-share',
  },
  {
    header: 'Products & Ideas',
  },
  {
    title: 'Search',
    icon: 'SearchIcon',
    route: 'pages-ideas-list',
  },
  {
    title: 'New Product or Idea',
    icon: 'CoffeeIcon',
    route: 'pages-ideas-new',
  },
  {
    header: 'Customers & Experts',
  },
  {
    title: 'Search',
    icon: 'SearchIcon',
    route: 'apps-e-commerce-shop',
  },
  {
    title: 'Your Profile',
    route: 'apps-profile',
    icon: 'UserIcon',
  },
  {
    header: 'Help',
  },
  {
    title: 'Faq',
    route: 'pages-faq',
    icon: 'HelpCircleIcon',
  },
  {
    header: 'Feedback'
  },
  {
    title: 'Report Error',
    route: 'apps-bug',
    icon: 'AlertCircleIcon',
  },
  {
    title: 'Submit Feature',
    route: 'apps-feature',
    icon: 'StarIcon',
  },
]
