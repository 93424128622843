<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content v-if="userData">
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p>
        <span class="user-status">{{ userData.type }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.image"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <!-- <b-dropdown-divider /> -->

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-edit-availability' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CalendarIcon"
        class="mr-50"
      />
      <span>Availability</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'integrations' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="BoxIcon"
        class="mr-50"
      />
      <span>Integrations</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-edit-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="EditIcon"
        class="mr-50"
      />
      <span>Edit Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { getAuth, signOut } from "firebase/auth";
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getFirestore, updateDoc, doc, arrayRemove} from 'firebase/firestore';

const db = getFirestore();
const auth = getAuth()

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data () {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    async logout () {
      const userData = JSON.parse(localStorage.getItem('userData'))

      // Update the profile to remove push token from the device
      if(userData.pushToken){
        updateDoc(doc(db, "profiles", auth.currentUser.displayName ), { pushTokens: arrayRemove(userData.pushToken)})
      }

      signOut(auth).then(() => {

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
      }).catch((error) => {
        console.log(error)
        const errorMessage = error.code.replace("auth/","").replaceAll("-", " ");
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Logout Failed`,
            icon: 'user-x',
            variant: 'danger',
            text: errorMessage,
          },
        })
      });
    },
  },
}
</script>
