<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BriefcaseIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          <b-link to="/app/onboarding"> Onboarding </b-link>
        </h4>
        <!-- <b-badge v-if="requiredNotifications > 0"
          pill
          variant="light-danger"
        >
          {{requiredNotifications}} Required
        </b-badge>
        <b-badge v-if="recommendedNotifications > 0"
          pill
          variant="light-primary"
        >
          {{recommendedNotifications}} Recommended
        </b-badge> -->
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- System Notifications -->
      <b-dropdown-item
        v-for="notification in onboardingNotifications"
        :key="notification.subtitle"
        :to="{ name: notification.route }"
        class="dropdown-onboarding"
      >
        <b-media v-if="notification.isCompleted === false">
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
            <b-badge
              pill
              :variant="notification.type"
              class="float-right"
            >
            {{ notification.status }}
            </b-badge>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-dropdown-item>
    </vue-perfect-scrollbar>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BDropdownItem,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { getAuth } from "firebase/auth";
import { getFirestore, getDoc, doc, updateDoc} from 'firebase/firestore';

const auth = getAuth();
const db = getFirestore();

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
     return {
      onboardingNotifications: null,
      notificationCount: 0
     }
  },
  mounted () {
    this.loadNotifications()
  },
  watch: {
    '$route'(to, from) {
      //this.loadNotifications()
    }
  },
  methods: {
    async loadNotifications() {
      let currentnotificationCount = 0
      // tODO find a better way to do this
      if(!auth || !auth.currentUser) return
      const profileRes = await getDoc(doc(db, "profiles", auth.currentUser.displayName))
      
      if(!profileRes.exists) return
      const userData = profileRes.data()

      if(!userData) return
      this.onboardingNotifications = [
        {
          title: 'Complete Profile',
          subtitle: "Add your tagline, industry, and bio before searching others' profiles.",
          type: 'light-danger',
          icon: 'AlertTriangleIcon',
          status: 'Required',
          isCompleted: (userData.profileComplete)?true:false,
          route: 'apps-edit-profile'
        },
        {
          title: 'Update Availability',
          subtitle: 'Set your weekly availability so others can automatically book time with you.',
          type: 'light-danger',
          icon: 'AlertTriangleIcon',
          status: 'Required', 
          isCompleted: (userData.availabilityUpdated)?true:false,
          route: 'apps-edit-availability'
        },
        {
          title: 'Product or Idea',
          subtitle: 'Looking to get feedback from customers or experts on your idea or product.',
          type: 'light-primary',
          icon: 'AlertTriangleIcon',
          status: 'Recommended',
          isCompleted: (userData.ideaPage)?true:false,
          route: 'pages-ideas-new',
        },
        {
          title: 'Enable Payments',
          subtitle: 'Save time and reduce no-shows by automatically collecting payments at the time of the booking.',
          type: 'light-primary',
          icon: 'AlertTriangleIcon',
          status: 'Pro Account',
          isCompleted: (userData.stripeComplete)?true:false,
          route: 'integrations-stripe'
        },
        {
          title: 'Sync Google Calendar',
          subtitle: 'Make sure to attend all meetings! Automatically creates a meeting event when a user requests a booking.',
          type: 'light-primary',
          icon: 'AlertTriangleIcon',
          status: 'Pro Account',
          isCompleted: (userData.googleToken)?true:false,
          route: 'integrations-google'
        },
        {
          title: 'Validate Account',
          subtitle: 'Give your account more credibility and visibility by connecting your LinkedIn profile.',
          type: 'light-primary',
          icon: 'AlertTriangleIcon',
          status: 'Pro Account',
          isCompleted: (userData.linkedinID)?true:false,
          route: 'integrations-linkedin',
        },
        {
          title: 'Share Profile',
          subtitle: 'Want more visibility to your profile? Share your profile on your social networks!',
          type: 'light-primary',
          icon: 'AlertTriangleIcon',
          status: 'Recommended',
          isCompleted: (userData.sharedProfile)?true:false,
          route: 'apps-share'
        },
      ]

      if(!userData.profileComplete)currentnotificationCount++
      if(!userData.availabilityUpdated)currentnotificationCount++
      if(!userData.stripeComplete)currentnotificationCount++
      if(!userData.linkedinID)currentnotificationCount++
      if(!userData.sharedProfile)currentnotificationCount++
      if(!userData.googleToken)currentnotificationCount++

      this.notificationCount = currentnotificationCount
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 80,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>
